<template>
    <Error />
</template>

<script>
    import Error from '~/components/Error404';

    export default {
        components: {
            Error
        },
	    middleware({ res }) {
			res.statusCode = 404;
	    }
    }
</script>
